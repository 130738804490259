<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.monitor.log.api.title.indexHeadTitle`)"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @row-view="rowDetail"
    >
    </grid-layout>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  getApiList as getList
} from '@/api/logs'

export default {
  name: "apiLog",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "serviceId",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.monitor.log.api.field.serviceId"),
        },
        {
          prop: "serverHost",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.monitor.log.api.field.serverHost"),
        },

      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.monitor.log.api.field.serviceId'),
            prop: 'serviceId',
            align: "left",
            width: 200,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.monitor.log.api.field.serverHost'),
            prop: 'serverHost',
            align: "left",
            width: 200,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.monitor.log.api.field.serverIp'),
            prop: 'serverIp',
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.monitor.log.api.field.title'),
            prop: 'title',
            align: "left",
            width: 200,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.monitor.log.api.field.method'),
            prop: 'method',
            align: "left",
            width: 200,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.monitor.log.api.field.requestUri'),
            prop: 'requestUri',
            align: "left",
            width: 200,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.monitor.log.api.field.createTime'),
            prop: 'createTime',
            align:'center',
            width: 200,
            overHidden: true,
          }
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.log_api_view) {
        result.push({
          label: this.$t('cip.cmn.btn.viewBtn'),
          emit: "row-view",
          type: "button",
          icon: ""
        });
      }
      return result

    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      return []
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    rowDetail(row) {
      this.$router.push({
        path: `/log/apiEdit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
